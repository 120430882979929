import { AsyncDispatchResult } from "utils/asyncAction";
import {
  UserRole,
  TagCountsByAreaAndPhase,
  Project,
  User,
  ApiV1ProjectsIdPutRequest,
  ApiV1ProjectsIdKpiGetRequest,
  ApiV1ProjectsPostRequest,
  PhaseCompletionStatus,
  Area,
  Tag,
  Asset,
  Phase as PhaseBasic,
  PhaseCompletion,
  ApiV1TagsIdTechnicianPostRequest,
  ApiV1ProjectsIdAreasTechnicianPostRequest,
  ApiV1ProjectsIdChecklistsPostRequest,
  UploadTag,
  AssetType,
  ApiV1ProjectsIdPhasesPostRequest,
  TagIssue,
  ApiV1ProjectsIdUsersPutRequest,
  TagImage,
  IssueImage,
  ApiV1ProjectsIdIssuesGetRequest,
  ApiV1ProjectsIdIssuesIssueIdPutRequest,
  ApiV1ProjectsIdTagsTagIdGetRequest,
  TagCompletionDetail,
  TagPhaseDetail,
  ApiV1ProjectsIdChecklistsChecklistIdPutRequest,
  ApiV1ProjectsIdChecklistsChecklistIdDeleteRequest,
  ApiV1ProjectsIdCopyChecklistPostRequest,
  ApiV1ProjectsIdPhasePutRequest,
  ApiV1ProjectsIdRejectChecklistPostRequest,
  ApiV1ProjectsIdExclusionListPutRequest,
  ApiV1ProjectsIdTagsPutRequest,
  ApiV1ProjectsIdCompletedchecklistpackagelinkGetRequest,
  ApiV1ProjectsIdGenerateCompletedChecklistPackagePostRequest,
  ApiV1ProjectsAreaAttachmentsGetRequest,
  ApiV1ProjectsIdAreasAttachmentPostRequest,
  ApiV1ProjectsAreaAttachmentsDeleteRequest,
  ApiV1ProjectsIdAttachmentPostRequest,
  ApiV1ProjectsAttachmentsDeleteRequest,
} from "data/api";
import { ChecklistToCopy } from "data/api/models/ChecklistToCopy";
import { TagAttachment } from "data/api/models/TagAttachment";

export interface AdminProjectsState {
  data: Project[];
  project: Project;
  kpis: TagCountsByAreaAndPhase[];
  issues: TagIssue[];
  tag: TagCompletionDetail;
  isLoadingProjects: boolean;
  isLoadingKpis: boolean;
  isLoadingTag: boolean;
  isLoadingProjectAreas: boolean;
  isLoadingProjectPhases: boolean;
  isLoadingProjectIssues: boolean;
  isCreatingProject: boolean;
  isCreatingChecklist: boolean;
  isEditing: boolean;
  isRemoving: boolean;
  isResending: boolean;
  isInviting: boolean;
  isUploadingCSV: boolean;
  isCsvUploaded: boolean;
  isDeletingProject: boolean;
  isExportingCSV: boolean;
  isCreatingPhases: boolean;
  isUpdatingIssue: boolean;
  isIssueUpdated: boolean;
  isGeneratingChecklistReport: boolean;
  error: Error | null;
  handoverPackageLink: string;
  shouldUpdateProjectPhases: boolean;
  isDeletingChecklist: boolean;
  checklistsToCopy: ChecklistToCopy[];
  isCopyingChecklist: boolean;
  isUpdatingPhase: boolean;
  completedChecklistPackageLink: string;
}

export interface Member {
  id: string;
  name: string;
  email: string;
  role: string;
  status?: string;
}

export interface Phase {
  id: string;
  name: string;
  statusOfCompletion: number;
  status: PhaseCompletionStatus;
}

export interface AssignableTech {
  id: number;
  name: string;
  email: string;
  role: UserRole;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Safe<T extends Record<string, any>> = {
  [K in keyof Required<T>]: NonNullable<T[K]>;
};

export type SafeArea = Safe<Omit<Area, "tags" | "users">> & { tags: TagRow[]; users: User[] };

export type TagRow = Safe<
  Omit<Tag, "createdAt" | "updatedAt" | "users" | "phaseCompletions" | "asset" | "images" | "issues" | "areas">
> &
  Pick<Asset, "createdAt" | "updatedAt"> & {
    asset?: Asset;
    users: User[];
    phaseCompletions: SafePhaseCompletion[];
    images: TagImage[];
    issues: SafeTagIssue[];
    flexFields: string;
  };

export type SafePhaseCompletion = Safe<Omit<PhaseCompletion, "dateCompleted" | "completedByUser">> & {
  completedByUser: string | null;
  dateCompleted: Date | null;
};

export type VerifiedUploadTag = UploadTag & { errors?: string[]; [key: string]: unknown };

export type SafeTagIssue = Safe<
  Omit<TagIssue, "updatedAt" | "images" | "createdAt" | "createdByUser" | "resolvedByUser">
> & {
  createdAt?: Date;
  updatedAt?: Date;
  createdByUser?: User;
  images?: IssueImage[];
  resolvedByUser?: User;
};

export type SafeTagPhaseDetail = Safe<TagPhaseDetail>;

export interface InviteTeamMemberRequest {
  id: number;
  email: string;
  role: UserRole;
}

export interface ChangeProjectMemberAccessRequest {
  userId: number;
  projectId: string;
  archive: boolean;
}

export interface PostTagsProjectUploadTagsRequest {
  projectId: number;
  uploadTags: UploadTag[];
}

export enum PhaseChecklistIssue {
  Critical,
  Normal,
  None,
}

export interface PhaseChecklist extends SafePhaseCompletion {
  issue: PhaseChecklistIssue;
}

export interface ProjectsActions {
  getProjects: () => AsyncDispatchResult;
  getProjectById: (projectId: number) => void;
  resetError: () => void;
  getProjectAreas: (projectId: number) => AsyncDispatchResult;
  getProjectPhases: (projectId: number) => AsyncDispatchResult;
  getProjectIssues: (projectId: ApiV1ProjectsIdIssuesGetRequest) => AsyncDispatchResult;
  getProjectAttachments: (projectId: number) => AsyncDispatchResult;
  getProjectAreaAttachments: (request: ApiV1ProjectsAreaAttachmentsGetRequest) => AsyncDispatchResult;
  postProjectAreaAttachments: (request: ApiV1ProjectsIdAreasAttachmentPostRequest) => AsyncDispatchResult;
  postProjectAttachments: (request: ApiV1ProjectsIdAttachmentPostRequest) => AsyncDispatchResult;
  deleteProjectAttachment: (request: ApiV1ProjectsAttachmentsDeleteRequest) => AsyncDispatchResult;
  createProject: (newProject: ApiV1ProjectsPostRequest) => AsyncDispatchResult;
  editProjectMember: (request: ApiV1ProjectsIdUsersPutRequest) => AsyncDispatchResult;
  changeProjectMemberAccess: (request: ChangeProjectMemberAccessRequest) => AsyncDispatchResult;
  uploadTagSheetCSV: (request: PostTagsProjectUploadTagsRequest) => AsyncDispatchResult;
  exportTagSheetCSV: (request: CSVExportRequest) => AsyncDispatchResult;
  exportMultipleTagSheetsXLSX: (request: CSVOneFileExportRequest) => AsyncDispatchResult;
  downloadTagSheetCSVTemplate: () => AsyncDispatchResult;
  verifyTagSheetCSV: (csv: TagRow[]) => AsyncDispatchResult;
  assignTagToTechs: (request: ApiV1TagsIdTechnicianPostRequest) => AsyncDispatchResult;
  getKpiById: (kpiRequest: ApiV1ProjectsIdKpiGetRequest) => AsyncDispatchResult;
  inviteTeamMember: (request: InviteTeamMemberRequest) => AsyncDispatchResult;
  assignAreaToTechs: (request: ApiV1ProjectsIdAreasTechnicianPostRequest) => AsyncDispatchResult;
  closeProject: (request: ApiV1ProjectsIdPutRequest) => AsyncDispatchResult;
  deleteProject: (request: ApiV1ProjectsIdPutRequest) => AsyncDispatchResult;
  createPhases: (request: ApiV1ProjectsIdPhasesPostRequest[]) => AsyncDispatchResult;
  createChecklist: (request: ApiV1ProjectsIdChecklistsPostRequest) => AsyncDispatchResult;
  updateChecklist: (request: ApiV1ProjectsIdChecklistsChecklistIdPutRequest) => AsyncDispatchResult;
  deleteChecklist: (request: ApiV1ProjectsIdChecklistsChecklistIdDeleteRequest) => AsyncDispatchResult;
  deleteAreaAttachment: (request: ApiV1ProjectsAreaAttachmentsDeleteRequest) => AsyncDispatchResult;
  updateProjectIssue: (request: ApiV1ProjectsIdIssuesIssueIdPutRequest) => AsyncDispatchResult;
  getTagById: (request: ApiV1ProjectsIdTagsTagIdGetRequest) => AsyncDispatchResult;
  getProjectHandoverLink: (projectId: number) => AsyncDispatchResult;
  getChecklistsAvailableForCopying: (projectId: number) => AsyncDispatchResult;
  copyChecklist: (request: ApiV1ProjectsIdCopyChecklistPostRequest) => AsyncDispatchResult;
  updatePhaseName: (request: ApiV1ProjectsIdPhasePutRequest) => AsyncDispatchResult;
  generateChecklistReport: () => AsyncDispatchResult;
  rejectChecklist: (request: ApiV1ProjectsIdRejectChecklistPostRequest) => AsyncDispatchResult;
  createOrUpdateExclusionList: (request: ApiV1ProjectsIdExclusionListPutRequest) => AsyncDispatchResult;
  updateProjectTag: (request: ApiV1ProjectsIdTagsPutRequest) => AsyncDispatchResult;
  generateCompletedChecklistPackage: (
    request: ApiV1ProjectsIdGenerateCompletedChecklistPackagePostRequest
  ) => AsyncDispatchResult;
  completedChecklistPackageLink: (
    request: ApiV1ProjectsIdCompletedchecklistpackagelinkGetRequest
  ) => AsyncDispatchResult;
  getFlexFieldsById: (id: number) => AsyncDispatchResult;
  updateUpdateableFlexFields: (request: ApiV1ProjectsIdPutRequest) => AsyncDispatchResult;
}

export interface CSVExportRequest {
  tags: Tag[];
  projectName: string;
  phases: PhaseBasic[];
}

export interface CSVOneFileExportRequest {
  tags: Array<Array<Tag>>;
  projectName: string;
  phases: Array<Array<PhaseBasic>>;
  worksheetNames: Array<string>;
}

interface CSVRow {
  tagId: string;
  area: string;
  assetType: AssetType;
  manufacturer: string;
  model: string;
  serialNumber: string;
}

export type CVSRowExportType = {
  // phasesStatuses: PhaseCompletionStatus[];
  phaseCompletions: SafePhaseCompletion[];
  fixedColumns: {
    [key in keyof CSVRow]: string | AssetType;
  };
  flexFields: string;
  tagIssues: SafeTagIssue[];
};

export enum PlainCSVHeaders {
  tagId = "tagid",
  area = "area",
  assetType = "assettype",
  manufacturer = "manufacturer",
  model = "model",
  serialNumber = "serialnumber",
}

export enum CSVHeaders {
  tagId = "Tag ID",
  area = "Area",
  assetType = "Asset Type",
  manufacturer = "Manufacturer",
  model = "Model",
  serialNumber = "Serial Number",
}

export enum TagKeys {
  tagId = "tagId",
  area = "area",
  assetType = "assetType",
  manufacturer = "manufacturer",
  model = "model",
  serialNumber = "serialNumber",
}

export enum KeysByPhase {
  Tech = "Tech",
  Date = "Date",
  Time = "Time",
}

export enum KeysByIssue {
  IssueOpened = "Issue Opened",
  IssueType = "Issue Type",
  CriticalIssue = "Critical Issue",
  Resolved = "Resolved",
}
